import { Experiment } from "@vp/ab-reader/lib/types/experiment"
import CampaignCalloutsServiceClient from "../clients/campaign-callouts-service-client"
import { IGetCampaignCalloutsResponse } from "../types"

/**
 * Utility function calling the service to pull the campaign data.
 * @param allExperiments List of experiments that the user is in
 * @param tenant `"vistaprint"` or `"promotique"`
 * @param culture "locale" you want to use.
 * @param previewDate Date you want to use to preview Campaign Callouts
 * @returns Object with campaign data per campaign ID.
 */
export const getCampaignCalloutsByLocale = async (allExperiments: Experiment[], tenant: string, culture: string, previewDate?: string): Promise<IGetCampaignCalloutsResponse> => {
  return new CampaignCalloutsServiceClient().getCalloutsByLocale(tenant, culture, allExperiments, previewDate)
}
