import React from "react"
import { CampaignContext } from "../context/CampaignContext"
import { useCampaignCallouts } from "../hooks/useCampaignCallouts"

export interface CampaignProviderProps {
  /**
   * Tenant is `"vistaprint"` or `"promotique"`.
   */
  tenant: string


  /**
   * Used to localize components and to pull current live campaign.
   */
  locale: string

  /**
   * Date to preview component behavior
   */
  previewDate?: string
}

const CampaignProvider = (props: React.PropsWithChildren<CampaignProviderProps>) => {
  const {
    tenant,
    locale,
    previewDate
  } = props

  const { callouts, skin, isLoading } = useCampaignCallouts(tenant, locale, previewDate, "provider")

  return (
    <CampaignContext.Provider
      value={{ tenant, locale, callouts, skin, isLoading }}
    >
      {props.children}
    </CampaignContext.Provider>
  )
}

export default CampaignProvider
