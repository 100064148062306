const getPricingContextCouponCode = () => {
  const doc = getDocument();
  return doc?.pcxtV3?.getCouponCode() ?? "";
};

const initializePricingContext = async (
  tenant: string,
  country: string,
  developmentMode?: boolean
) => {
  const doc = getDocument();
  await doc?.pcxtV3?.init(tenant, country, developmentMode);
};

const isPricingContextInitialized = (developmentMode?: boolean): boolean => {
  const doc = getDocument()
  return developmentMode ? true : doc?.pcxtV3?.isInitialized() ?? false;
};

const getDocument = (): any => {
  return typeof document !== "undefined" ? document : undefined;
};

export {
  getPricingContextCouponCode,
  initializePricingContext,
  isPricingContextInitialized,
};
