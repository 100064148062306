import React from "react"
import { ICampaignCallouts } from "../types"

export interface ICampaignContext {
  tenant: string
  locale: string
  callouts: ICampaignCallouts,
  skin: string,
  isLoading: boolean
}

export const CampaignContext = React.createContext<ICampaignContext>({ tenant: "", locale: "", callouts: {}, skin: "", isLoading: false })
