import { useEffect, useState } from "react";
import {
  ICampaignCalloutResponse,
  IGetCampaignCalloutsResponse,
} from "../types";
import { getCampaignCalloutsByLocale } from "../utils";
import { usePersistedData } from "./usePersistedData";
import * as reader from "@vp/ab-reader";
import { Experiment } from "@vp/ab-reader/lib/types/experiment";
import usePricingContextInitialization from "./usePricingContextInitialization";

export const useCampaignCallouts = (
  tenant: string,
  locale: string,
  previewDate?: string,
  id?: string
) => {
  const [isLoading, setIsLoading] = useState(false);
  const calloutsCacheKey = `campaign_callouts_campaigns_${
    tenant?.toLowerCase() ?? ""
  }_${locale?.toLowerCase() ?? ""}_${previewDate ?? ""}`;

  // pricing context initialize part to the place where the API call for callouts is made. This avoids multiple init cycles and hence multiple re-render cycles of the component.
  usePricingContextInitialization(tenant, locale);

  const [result] = usePersistedData<ICampaignCalloutResponse>({
    cacheKey: calloutsCacheKey,
    fetchValue: async (callback) => {
      setIsLoading(true);
      reader.initialize();
      let allExperiments: Experiment[] = [];
      await reader.waitTillAvailable(1000);
      try {
        allExperiments = reader.getAllExperiments();
      } catch (err) {
        console.error(
          `Could not get experiments for callout campaigns due to ${err}`
        );
      }
      try {
        const campaignData = await fetchCampaigns(
          allExperiments,
          tenant,
          locale,
          previewDate
        );

        if (previewDate != null && previewDate !== "") {
          campaignData.result.cache = {
            expiredTTL: 1,
            staleTTL: 1,
          };
        }

        const newValue: ICampaignCalloutResponse = {
          callouts: campaignData.result.callouts,
          skin: campaignData.result.skin,
        };
        callback(newValue, campaignData.result.cache);
        if (campaignData.context != null) {
          fireCampaignCalloutsImpression(campaignData.context);
        }
      } catch (err) {
        console.error(`Could not get callout campaigns due to: ${err}`);
      } finally {
        // Ensure that the component is still mounted before updating loading state
        setIsLoading(false);
      }
    },
    id,
  });

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return {
    callouts: result?.callouts ?? {},
    skin: result?.skin ?? "",
    isLoading,
  };
};

async function fetchCampaigns(
  allExperiments: Experiment[],
  tenant?: string,
  locale?: string,
  previewDate?: string
): Promise<IGetCampaignCalloutsResponse> {
  let campaigns: IGetCampaignCalloutsResponse = {
    result: {
      callouts: {},
      skin: "",
    },
  };

  if (!tenant || !locale) {
    return campaigns;
  }

  campaigns = await getCampaignCalloutsByLocale(
    allExperiments,
    tenant,
    locale,
    previewDate
  );

  return campaigns;
}

async function fireCampaignCalloutsImpression(
  context: Experiment
): Promise<void> {
  reader.fireImpression(context.experimentKey, context.variationKey);
}
