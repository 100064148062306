import { IGetCampaignCalloutsResponse } from "../types"

export function getMockCallouts (campaignId?: string): IGetCampaignCalloutsResponse {
  if (!campaignId) {
    return {
      result: {
        callouts: {
          standardBusinessCards: { value: "12% OFF" }
        },
        skin: "standard"
      }
    }
  } else {
    return {
      result: {
        callouts: {
          standardBusinessCards: { value: "UP TO 50% OFF" }
        },
        skin: "standard"
      }
    }
  }
}

export function isMockTenant (tenant: string) {
  return tenant === "mock"
}
