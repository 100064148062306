import { Experiment } from "@vp/ab-reader/lib/types/experiment"
import { getMockCallouts, isMockTenant } from "../mock/mockData"
import axios from "../providers/axios-provider"
import { IGetCampaignCalloutsResponse } from "../types"

const SERVICE_HOST = "https://campaign-callouts.prod.merch.vpsvc.com"

const REQUESTOR = "campaignCalloutsComponent"

class CampaignCalloutsServiceClient {
  async getCalloutsByLocale (tenant: string, locale: string, allExperiments: Experiment[], previewDate?: string): Promise<IGetCampaignCalloutsResponse> {
    if (isMockTenant(tenant)) {
      return getMockCallouts()
    }

    let serviceReponse

    try {
      if (previewDate == null || previewDate.trim().length === 0) {
        serviceReponse = await axios.post(`${SERVICE_HOST}/api/v2/calloutsForUserContexts/${tenant}/${locale.toLowerCase()}?requestor=${REQUESTOR}`, allExperiments)
      } else {
        serviceReponse = await axios.post(`${SERVICE_HOST}/api/v2/calloutsForUserContexts/${tenant}/${locale.toLowerCase()}?previewDate=${previewDate}&requestor=${REQUESTOR}`, allExperiments)
      }
      return serviceReponse.data
    } catch (e) {
      throw new Error(`Couldn't perform request against campaign callouts service due to ${e}`)
    }
  }
}

export default CampaignCalloutsServiceClient
