import { useEffect, useState } from "react"

interface ICachedState<TValue> {
  key: string;
  value: TValue;
  staleTime: number;
  expireTime: number;
}

export interface ICacheConfig {
  staleTTL: number,
  expiredTTL: number,
}

interface UsePersistedDataConfig<TValue> {
  cacheKey: string;
  fetchValue: (
    setValue: (newValue: TValue | undefined, cache?: ICacheConfig) => void
  ) => Promise<void>;
  id?: string;
  cacheConfig?: ICacheConfig
}

export const defaultCacheConfig: ICacheConfig = {
  staleTTL: 10,
  expiredTTL: 30
}

export function usePersistedData<TValue> (
  config: UsePersistedDataConfig<TValue>
) {
  const {
    cacheKey,
    fetchValue,
    id
  } = config

  // Combine the cacheKey and id to create a unique key for localStorage
  const storageKey = `${cacheKey}_${id ?? "default"}`
  const isBrowser = typeof window !== "undefined"
  // Retrieve the state from localStorage or initialize as undefined
  const [state, setState] = useState<ICachedState<TValue> | undefined>(() => {
    const storedValue = isBrowser ? localStorage.getItem(storageKey) : null
    return storedValue ? JSON.parse(storedValue) : undefined
  })

  const [valueToReturn, setValueToReturn] = useState<TValue | undefined>(
    state?.value
  )

  const setRefreshedState = (
    key: string,
    value: TValue | undefined,
    cacheConfig: ICacheConfig,
    checkCurrent: () => boolean
  ) => {
    if (checkCurrent() && isBrowser) {
      if (value) {
        const cacheValue = {
          key,
          value,
          staleTime: Date.now() + cacheConfig.staleTTL * 1e3,
          expireTime: Date.now() + cacheConfig.expiredTTL * 1e3
        }

        // Persist the updated state to localStorage
        localStorage.setItem(storageKey, JSON.stringify(cacheValue))

        setState(cacheValue)
      }
    }
  }

  useEffect(() => {
    let isCurrent = true
    const checkCurrent = () => isCurrent

    // Refresh the initial state if necessary
    if (isCacheStale(state) || isCacheExpired(state, cacheKey)) {
      fetchValue((newValue, cacheConfig = defaultCacheConfig) =>
        setRefreshedState(cacheKey, newValue, cacheConfig, checkCurrent)
      )
    }

    return () => {
      isCurrent = false
    }
  }, [cacheKey])

  useEffect(() => {
    // Return undefined if the value is expired
    const returnedStateValue = isCacheExpired(state, cacheKey)
      ? undefined
      : state?.value
    setValueToReturn(returnedStateValue)
  }, [state])

  return [valueToReturn]
}

const isCacheStale = (cache: ICachedState<any> | undefined): boolean => {
  return !cache || Date.now() > cache.staleTime
}

const isCacheExpired = (
  cache: ICachedState<any> | undefined,
  stateKey: string
): boolean => {
  return !cache || Date.now() > cache.expireTime || cache.key !== stateKey
}
