import axios, { type AxiosInstance } from "axios"
import axiosRetry, { type IAxiosRetryConfig } from "axios-retry"

class AxiosProvider {
  private static instance: AxiosInstance

  public static getDefaultInstance (): AxiosInstance {
    if (AxiosProvider.instance == null) {
      AxiosProvider.instance = axios.create()
      axiosRetry(AxiosProvider.instance)
    }

    return AxiosProvider.instance
  }

  public static createRetryInstance (retryPolicy?: IAxiosRetryConfig): AxiosInstance {
    const client = axios.create()
    retryPolicy != null ? axiosRetry(client, retryPolicy) : axiosRetry(client)

    return client
  }
}

const defaultAxios = AxiosProvider.getDefaultInstance()

export {
  defaultAxios as default,
  AxiosProvider,
  AxiosInstance,
  IAxiosRetryConfig
}
