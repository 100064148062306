import styled from "styled-components";
import { Callout } from "@vp/swan";

export const StyledCallout = styled(Callout)`
  width: fit-content;
`;

export const StyledText = styled.span`
  max-width: 13ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
