import { useEffect } from "react";
import { initializePricingContext, isPricingContextInitialized } from "../utils/pricingContext";

const usePricingContextInitialization = (tenant: string, locale: string) => {
  useEffect(() => {
    if (!isPricingContextInitialized()) {
      if (locale && tenant) {
        const initializePricingContextAndSetState = async () => {
          const country = locale?.substring(locale.length - 2);
          await initializePricingContext(tenant, country, false);
        }
        initializePricingContextAndSetState();
      }
    }
  }, [tenant, locale])
}

export default usePricingContextInitialization;
