import { CalloutProps } from "@vp/swan";
import React, { useState, useEffect } from "react";
import { useCampaignContext } from "../hooks/useCampaignContext";
import { StyledCallout, StyledText } from "./CampaignCallouts.styles";
import {
  getPricingContextCouponCode,
  isPricingContextInitialized
} from "../utils/pricingContext";

interface CampaignCalloutProps extends CalloutProps {
  /**
   * `product` value is an MPV ID for which the `Callout` (`@vp/swan` component) should be rendered.
   */
  product: string;
  children?: never;
  developmentMode?: boolean;
}

const CampaignCallout = React.forwardRef<any, CampaignCalloutProps>(
  ({ developmentMode, ...props }, ref) => {
    const { callouts, locale, skin } = useCampaignContext();
    const [couponCode, setCouponCode] = useState(undefined);
    const [overridenSkin, setOverridenSkin] = useState<
      | "info"
      | "promo"
      | "holiday"
      | "error"
      | "accent"
      | "warning"
      | "success"
      | "help"
    >("info");
    const [overridenVariant, setOverridenVariant] = useState<
      "standard" | "inverse"
    >("standard");

    useEffect(() => {
      if (skin !== "") {
        switch (skin) {
          case "standard":
            setOverridenSkin("info");
            setOverridenVariant("standard");
            break;
          case "holidayInverse":
            setOverridenSkin("holiday");
            setOverridenVariant("inverse");
            break;
          case "announcementInverse":
            setOverridenSkin("promo");
            setOverridenVariant("inverse");
            break;
        }
      }
    }, [skin]);

    const pricingContextInitialized = isPricingContextInitialized(developmentMode);
    useEffect(() => {
      if (pricingContextInitialized) {
        setCouponCode(getPricingContextCouponCode());
      }
    }, [pricingContextInitialized]);

    if (!callouts) {
      return <></>;
    }

    const calloutForProduct = callouts[props.product];

    if (!calloutForProduct || !locale) {
      return <></>;
    }

    return (
      <>
        {couponCode === "" && (
          <StyledCallout
            {...props}
            skin={overridenSkin}
            variant={overridenVariant}
            ref={ref}
          >
            <StyledText>{calloutForProduct}</StyledText>
          </StyledCallout>
        )}
      </>
    );
  }
);

CampaignCallout.displayName = "CampaignCallout";

export default CampaignCallout;
